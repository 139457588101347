import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { Dialog, Paper, TextField, Button } from "@material-ui/core";
import _ from "lodash";
import { request, useCancel } from "../../../data/store";
import DialogHeader from "../../dialog/DialogHeader";
import DialogFooter from "../../dialog/DialogFooter";
import DialogButton from "../../dialog/DialogButton";
import Log from "../../../data/log";
import { EMPTY_STRING } from "../../../config";

const useStyles = makeStyles({
  root: {
    height: '100%',
    flexGrow: 1,
    maxWidth: 400
  },
  reportLinks: {
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
    },
    textDecoration: 'underline',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  box: {
      padding: 20,
      margin: 20,
      textAlign: 'left',
      width: '90%',
  },
  buttons: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
},
  valueTreeRow: {
    
  }
});

const showMoreStr = '...Show More';
const showUnderscoreMore = 'SHOW_MORE';
const slash = '/';


export default function SaoTree() {
  const id = useCancel();
  const classes = useStyles();
  const [treeData, setTreeData] = useState();
  const [pathsFetched, setPathsFetched] = useState(new Set());
  const [pathsNeedMoreData, setPathsNeedMoreData] = useState(new Set());
  const [valueTreeData, setValueTreeData] = useState();
  const [open, setOpen] = useState(false);
  const [expandedIds, setExpandedIds] = useState([]);
  const [inputValue, setInputValue] =  useState(EMPTY_STRING);
  const [selected, setSelected] =  useState(EMPTY_STRING);

  useEffect(() => {
    const fetchInitialData = async () => {
      const roots = await request({
        component: id,
        type: 'Administration',
        symbol: 'SAO_ROOT_ELEMENTS'
      })
      setTreeData(_.map(roots, root => {
        return {
          id: root,
          name: root,
        }
      }))
    }
    fetchInitialData();
  }, [id]);

  const invalidPath = React.useMemo(() => !_.isEmpty(inputValue) && !inputValue.startsWith(slash), [inputValue]);

  const getHelperText = React.useMemo(
    () => (invalidPath ? `Path must start with a '${slash}'` : EMPTY_STRING),
    [inputValue]
  );

  const handleSelect = (event, keys) => {
    setSelected(keys);
    setInputValue(`${slash}${keys}`);
  };

  const handleCollapseAll = () => {
    setExpandedIds([]);
    setSelected(EMPTY_STRING);
    setInputValue(EMPTY_STRING);
  };

  // eslint-disable-next-line id-length
  async function onLoadData(event, keys) {
    setExpandedIds(keys)
    const path = keys[0];
    if(_.isUndefined(path) || (pathsFetched.has(path) && !pathsNeedMoreData.has(path))){
      return;
    } 
    const paths = path.split(slash);
    const showMore = paths[paths.length - 1] === showUnderscoreMore
    if(showMore) {
      paths.pop()
    }
    setTreeData(await updateTreeData(treeData, paths, path, showMore, null));
    setPathsFetched(pathsFetched.add(path));
  }

  const getHandleLabelClick = (event, nodeId, value) => {
    if(_.isNil(value)){
      return;
    }
    Log.debug("Value: ", value);
    setValueTreeData(updateValueTreeData(value, 0, nodeId));
    setOpen(true);
    event.preventDefault();
  }

  function updateValueTreeData(value, count, key){
    if(Array.isArray(value)){
      return value.map(inner => updateValueTreeData(inner, count, key))
    }
    if(typeof value === 'object') {
      return (
      <div style={{paddingBottom: '20px', margin: '0px'}}>
        <p style={{paddingLeft: `calc(${count}*30px)`, margin: '0px'}}> <strong>{key}:</strong> </p>
        { value && Object.keys(value).map(innerKey => updateValueTreeData(value[innerKey], count + 1, innerKey))}
      </div>
      )
    }
    return <p key={key + value + count} style={{paddingLeft: `calc(${count}*30px)`, margin: '0px'}}>
        <strong>{key}</strong> : {`${_.isNil(value) ? undefined : value}`}
      </p>;
  }

  async function updateTreeData(list, paths, key, showMore, nextPathElement) {
    const newList = [];
    for(const item of list){
      if (item.name === paths[0]) {
        if(paths.length !== 1 && !item.isLeaf /* for cases when users wrongly request to load children of a leaf node */){
          newList.push({ 
            ...item,
            children: await updateTreeData(item.children, paths.slice(1 - paths.length), key, showMore, nextPathElement),
          });
        } else {
          newList.push(await toLeaf(item, showMore, key, nextPathElement))
        }
      } else {
        newList.push(item);
      }
    }
    return newList;
  }

  async function toLeaf(item, showMore, key, nextPathElement) {
    let index = item.index || 0;
    let path;
    if(index !== 0 && !showMore){
      pathsNeedMoreData.delete(key)
      setPathsNeedMoreData(pathsNeedMoreData);
      return item;
    }
    path = key;
    if(showMore){
      const keys = key.split(slash);
      keys.pop();
      path = keys.join(slash);
    }
    index+=1;
    const [newLayers, value] =
    await Promise.all([
      request({
        component: id,
        type: 'Administration',
        symbol: 'LIMITED_SAO_ELEMENTS',
        sParamOne: path.startsWith(slash) ? path : `${slash}${path}`,
        sParamTwo: index,
        nextPathElement
      }),
      request({
        component: id,
        symbol: 'VALUE',
        path: `${slash}${key}`
      })
    ]);
    if(_.isEmpty(newLayers)){
      pathsNeedMoreData.delete(key)
      setPathsNeedMoreData(pathsNeedMoreData);
      return {
        ...item,
        isLeaf: !item.children && true,
        value,
      };
    }
    const showMoreNew = newLayers.length === 100 || (!_.isNull(nextPathElement) && newLayers.length === 101);
    const children = [];
    for (let i = 0; i < newLayers.length; i += 1) {
      const child = newLayers[i];
      if (_.findIndex(item.children, itemChild => itemChild.name === child) === -1) {  /* make sure the child has not been already loaded using provided path */
        children.push({
          id: `${path}/${child}`,
          name: child
        })
      }
    }
    if(item.children){
      item.children.pop();
    }
    
    if(showMoreNew){
      setPathsNeedMoreData(pathsNeedMoreData.add(`${path}${slash}${showUnderscoreMore}`));
      children.push({
        id: `${path}${slash}${showUnderscoreMore}`,
        name: showMoreStr,
      })
    }
    return { 
      ...item,
      children: (item.children || []).concat(children),
      value,
      showMoreNew,
      index
    };
  }

  const renderTree = useCallback(
    (items) =>
      items.map((item) => (
        <TreeItem
          key={item.id}
          nodeId={item.id}
          onLabelClick={(event) => getHandleLabelClick(event, item.id, item.value)}
          label={<div className={item.value ? classes.reportLinks : EMPTY_STRING}>{item.name}</div>}
        >
          { getChildren(item) }
        </TreeItem>
      )),
    []
  );

  function getChildren(item) {
    if (item.children) {
      const sortedChildren = _.sortBy(item.children, child => {
        let name = child.name.toLowerCase();
        if (!_.isNaN(+name)) {
          name = parseInt(name, 10);
        }
        if (name === showMoreStr.toLowerCase()) {
          return Infinity; // put "...Show More" at the end
        }
        return name;
      });
      return renderTree(sortedChildren);
    }
    if (item.isLeaf) {
      return null;
    }
    return " ";
  }

  if(_.isUndefined(treeData)){
    return null;
  }

  const handleOnClose = () => {
    setOpen(false);
    setValueTreeData(undefined);
  }

 async function handleLoadPath(providedPath) {
  if(_.isUndefined(providedPath) || (pathsFetched.has(providedPath) && !pathsNeedMoreData.has(providedPath))){
    return;
  }
  const paths = providedPath.substring(1).split(slash);
  let currentPath = EMPTY_STRING;
  let updatedTreeData = null;
  let updatedPathsFetched = null;
  let updatedExpandedIds = null;

  for (let i = 0; i < paths.length; i += 1) {
    currentPath = i===0 ? paths[i] : `${currentPath}${slash}${paths[i]}`;
    [ updatedTreeData, updatedPathsFetched, updatedExpandedIds ] = await updateTreeWithoutStateUpdate(currentPath, updatedTreeData || treeData, updatedPathsFetched || pathsFetched, updatedExpandedIds || expandedIds, paths[i===paths.length-1 ? i : i+1]);
  } 

  setPathsFetched(updatedPathsFetched)
  setTreeData(updatedTreeData);
  setExpandedIds(updatedExpandedIds);
};

async function updateTreeWithoutStateUpdate (currentPath, data, fetched, expanded, nextPathElement){
  const paths = currentPath.split(slash);
  const showMore = pathsNeedMoreData.has(`${currentPath}${slash}${showUnderscoreMore}`);;
  if (!_.includes(expandedIds, currentPath)) { 
    expandedIds.push(currentPath); 
  }
  return [ await updateTreeData(data, paths, showMore ? `${currentPath}${slash}${showUnderscoreMore}` : currentPath, showMore, nextPathElement), fetched.add(currentPath), expanded ];
}

  return (
    <div className={classes.container}>
        <Paper className={classes.box}>
        <div className={classes.stack}>
            <TextField
              label="Path to Load"
              className={classes.stackElement}
              style={{ minWidth: '100%' }}
              variant="outlined"
              value={inputValue}
              helperText={getHelperText}
              error={invalidPath}
              id="outlined-controlled"
              onChange={(event) => {
                  setInputValue(event.target.value);
              }}
            />
          </div>

          <div className={classes.buttons}>
                 <Button color="primary" variant="contained" onClick={async () => {handleLoadPath(inputValue);}}>Load Path</Button>
                 <Button color="primary" variant="contained" onClick={handleCollapseAll}>Collapse All</Button>
          </div>

      </Paper>
      <Paper className={classes.box}>
        <TreeView
          className={classes.root}
          // defaultExpanded={["1"]}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeToggle={onLoadData}
          onNodeSelect={handleSelect}
          expanded={expandedIds}   
          selected={selected}
        >
          {renderTree(treeData)}
        </TreeView>
      </Paper>
      {valueTreeData && 
        <Dialog onClose={handleOnClose} open={open} maxWidth="lg" fullWidth scroll="paper">
          <DialogHeader>
              <h2> Object </h2>
          </DialogHeader>
          <Paper>
              { valueTreeData }
          </Paper>
              <DialogFooter>
                  <DialogButton onClick={handleOnClose} color="secondary">
                      Close
                  </DialogButton>
              </DialogFooter>
        </Dialog> }
    </div>
  );
}
